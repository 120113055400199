import api from "../axios_service.js";

export async function fetchClienteEmpresaEquipe(id) {
  const response = await api.get(`/gestor/clientes-empresa-equipe/${id}`);
  return response.data.result;
}

export async function getNotSelected(id) {
  const response = await api.get(`/gestor/empresa-equipe-nao-selecionado/${id}`);
  return response.data.result;
}

export function postClienteEmpresaEquipe(local) {
  let body = {};
  for (let key in local) {
    body[key] = local[key];
  }
  return api.post("/gestor/clientes-empresa-equipe/add", body);
}

export function deleteClienteEmpresaEquipe(id) {
  return api.delete(`/gestor/clientes-empresa-equipe/${id}`);
}
