<template>
  <v-card flat>
    <v-card-title class="text-subtitle-1 accent py-3">
      Equipe que atendem este cliente
      <v-spacer></v-spacer>
      <v-btn
        small
        @click="openDialog"
        align="center"
        color="secondary"
        class="white--text"
      >
        Adicionar
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text
      v-if="cliente_empresa_equipe && cliente_empresa_equipe.length"
      class="py-4"
    >
      <v-chip
        v-for="(colaborador, index) in cliente_empresa_equipe"
        :key="index"
        class="ma-1"
        label
        close
        close-icon="mdi-delete"
        @click:close="delClienteEmpresaEquipe(colaborador.id)"
      >
        <v-icon left>mdi-account</v-icon>
        {{ colaborador.colaborador }}
      </v-chip>
    </v-card-text>
    <v-card-text v-else class="pa-4">
      Nenhum colaborador vinculado.
    </v-card-text>

    <v-dialog v-model="dialog" width="100%" max-width="400">
      <v-card
        :loading="loadingClienteEmpresaEquipe"
        :disabled="loadingClienteEmpresaEquipe"
      >
        <v-card-title class="text-subtitle-1 accent lightgray">
          Adicionar Colaborador ao Cliente
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-10">
          <v-form ref="form" v-model="validForm" lazy-validation>
            <v-combobox
              hide-selected
              v-model="selectedColaborador"
              :items="equipes"
              label="Colaborador"
              item-text="nome"
              item-value="id"
              dense
              return-object
              :rules="formRules"
            ></v-combobox>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!validForm"
            @click="createColaborador"
            color="secondary"
            depressed
            class="white--text"
            small
          >
            Adicionar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  fetchClienteEmpresaEquipe,
  postClienteEmpresaEquipe,
  deleteClienteEmpresaEquipe,
  getNotSelected,
} from "@/api/gestor/cliente_empresa_equipe.js";

import { inputRequired } from "@/helpers/utils.js";

export default {
  name: "QuemAtende",

  props: {
    cliente_id: {
      type: [Number, String],
      required: true,
    },
  },

  data() {
    return {
      dialog: false,
      loading: false,
      cliente_empresa_equipe: [],
      cliente_empresa_colaborador: {
        cliente_id: null,
        empresa_equipe_id: null,
      },
      validForm: true,
      loadingClienteEmpresaEquipe: false,
      equipes: [],
      selectedColaborador: null,
      formRules: [inputRequired],
    };
  },

  watch: {
    selectedColaborador() {
      if (this.selectedColaborador) {
        this.cliente_empresa_colaborador.empresa_equipe_id = this.selectedColaborador.id;
      }
    },
  },

  methods: {
    openDialog() {
      this.getEquipesNotSeleted();
      this.selectedColaborador = null;
      this.dialog = true;
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
    },

    getClienteEmpresaEquipe() {
      this.loading = true;
      fetchClienteEmpresaEquipe(this.cliente_id)
        .then((response) => {
          this.cliente_empresa_equipe = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    createColaborador() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.cliente_empresa_colaborador.cliente_id = this.cliente_id;
        postClienteEmpresaEquipe(this.cliente_empresa_colaborador)
          .then((response) => {
            if (response.status === 201) {
              this.$toast.success("Colaborador adicionado com sucesso");
              this.getClienteEmpresaEquipe();
              this.dialog = false;
              this.loading = false;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },

    getEquipesNotSeleted() {
      this.loading = true;
      getNotSelected(this.cliente_id)
        .then((response) => {
          this.equipes = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    delClienteEmpresaEquipe(id) {
      this.loading = true;
      deleteClienteEmpresaEquipe(id)
        .then(() => {
          this.$toast.success("Colaborador removido");
          this.getClienteEmpresaEquipe();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.getClienteEmpresaEquipe();
  },
};
</script>

<style lang="scss"></style>
